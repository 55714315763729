<template>
  <v-dialog v-model="dialogPackageDetail" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="oee-theme">
        <v-btn icon dark @click="closePopup">
          <v-icon>fa-times-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{isChinessLanguage ? '包裹编号' : 'Đóng bao ngày'}} {{currentTime | display_date('DD-MM-YYYY')}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-layout row wrap pa-3>
          <v-flex lg5 md5 sm5 pr-4>
            <v-layout row wrap pl-5 pt-8>
              <!-- <v-flex lg12 md12 sm12 xs12 pa-0 ma-0>
                <v-checkbox :label="isChinessLanguage ? '电商输入信息' : 'Nhập thông tin TMĐT'" v-model="useTMDT"></v-checkbox>
              </v-flex> -->
              <!-- <v-flex lg12 md12 sm12 xs12 pa-0 v-if="(currentItem.Weight - currentItem.RealWeight) >= 1">
                <div class="text-error">Cân thực tế nhỏ hơn tổng cân nặng các bill trong bao hàng.</div>
              </v-flex> -->
              <v-flex lg12 md12 sm12 xs12 pa-0>
                <v-text-field
                  :label="isChinessLanguage ? '包裹编号（自动）' : 'Mã bao hàng (auto)'"
                  :disabled="autoPackageCode"
                  v-model="currentItem.PackageCode"
                  class="input-group--focused"
                ></v-text-field>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0>
                <v-autocomplete class="input-group--focused" clearable :label="isChinessLanguage ? '客户' : 'Khách hàng'" v-model="currentItem.UID" :items="customers" item-text="CustomerCode" item-value="ID" @change="onSelectedCustomer" :disabled="currentItem.PackageCode != ''"></v-autocomplete>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0>
                <v-autocomplete class="input-group--focused" :label="isChinessLanguage ? '分类' : 'Loại hàng'" v-model="currentItem.ProductType" :items="product_types" item-text="name" item-value="value"></v-autocomplete>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 pt-3>
                <h-currency-input
                  :label="isChinessLanguage ? '数量' : 'Số gói hàng'"
                  v-model="currentItem.NumberOfPack"
                  :decimal="0"
                  :disabled="readOnly"
                  class="input-group--focused"
                ></h-currency-input>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 pt-3>
                <h-currency-input
                  :label="isChinessLanguage ? '重量' : 'Tổng cân nặng'"
                  v-model="currentItem.Weight"
                  :decimal="2"
                  :disabled="readOnly"
                  class="input-group--focused"
                ></h-currency-input>
              </v-flex>
              <!-- <v-flex lg12 md12 sm12 xs12 pa-0 pt-3>
                <h-currency-input
                  :label="isChinessLanguage ? '实际重量' : 'Cân nặng thực tế'"
                  v-model="currentItem.RealWeight"
                  :decimal="1"
                  :disabled="readOnly"
                  class="input-group--focused"
                ></h-currency-input>
              </v-flex> -->
              <v-flex lg12 md12 sm12 xs12 pa-0 pt-3>
                <v-select
                  class="pa-0"
                  :label="isChinessLanguage ? '出货仓库' : 'Kho xuất'"
                  :items="chinaStores"
                  v-model="selectedChinaStoreID"
                  item-text="Name"
                  item-value="ID"
                  :disabled="true"
                ></v-select>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 pt-3>
                <v-select
                  class="pa-0"
                  :label="isChinessLanguage ? '收货仓库' : 'Kho nhận'"
                  :items="warehouses"
                  v-model="currentItem.ReceivePlaceID"
                  item-text="Name"
                  item-value="ID"
                  :disabled="readOnly"
                ></v-select>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0 pt-3>
                <v-btn class="mr-2" v-if="!readOnly" :disabled="!CorrectData" color="blue darken-1 white--text" @click="saveItem">{{isChinessLanguage ? '保存' : 'Đóng bao'}}</v-btn>
                <v-btn color="red darken-1 white--text" @click="printBigpackage">{{isChinessLanguage ? 'Print' : 'In tem'}}</v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex lg7 md7 sm7 pl-3 pr-2>
            <v-layout row wrap pa-3 pt-8>
              <!-- <v-flex lg12 md12 sm12 xs12 pa-0 ma-0>
                <v-checkbox label="Sử dụng máy đọc barcode" v-model="useBarcodeReader"></v-checkbox>
              </v-flex> -->
              <v-flex lg12 md12 sm12 xs12 pa-0>
                <v-text-field
                  :label="isChinessLanguage ? '入单号(enter)' : 'Nhập mã bill (enter)'"
                  ref="trancode"
                  v-model="currentTransaction.TransactionCode"
                  class="input-group--focused"
                  @input="codeChanged"
                  @keyup.enter="manualChanged"
                  :disabled="readOnly"
                ></v-text-field>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0>
                <div class="text-error">{{message}}</div>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12 pa-0>
                <v-data-table
                  :headers="headers"
                  :items="currentItem.Packages"
                  hide-default-footer
                  disable-pagination
                  :must-sort="true"
                  :loading="loading"
                  :mobile-breakpoint="100"
                  fixed-header height="750px" 
                >
                  <template v-slot:item="{ item, index }">
                    <tr>
                      <td>{{ item.TransactionCode }}</td>
                      <!-- <td>{{ item.TransactionCodeVN }}</td> -->
                      <td class="text-right">{{ item.ChinaWeight | currency('', 2)}}</td>
                      <td class="text-right">
                        {{ (item.ChinaLength*item.ChinaWidth*item.ChinaHeight/1000000) | currency('', 3) }}
                        <v-divider></v-divider>
                        {{ (item.ChinaLength | 0) + ' x ' + (item.ChinaWidth | 0) + ' x ' + (item.ChinaHeight | 0) }}
                      </td>
                      <td class="text-center">
                        <v-icon v-if="!readOnly" small @click="deleteTransaction(index)">fa-trash-alt</v-icon>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-flex>          
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
import Vue from 'vue';
import _ from "lodash";
import moment from "moment";
import { getCurrentTime, sendErrorNotice, playSound } from "@/commons/utils";
import { request_list, print_bigpackage } from '@/commons/hpod';
export default {
  name: "big-package-popup",
  data() {
    let configPaging = _.cloneDeep(configs.PAGINATION);
    configPaging.itemsPerPage = 5;
    return {
      useBarcodeReader: false,
      useTMDT: false,
      autoPackageCode: configs.SYSTEM_CONFIG.auto_pack_code,
      message: '',
      currentTransaction: {
        TransactionCode: ""
      },
      requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
      pagination: configPaging,
      items_per_page: { "items-per-page-options": [5] },
      currentTime: getCurrentTime()
    };
  },
  props: ["dialogPackageDetail", "readOnly"],
  watch: {
    dialogPackageDetail() {
      if (this.dialogPackageDetail) {
        this.$nextTick(() => this.$refs.trancode.focus());
        this.currentItem.UID = 0;
      }
    },
    useBarcodeReader() {
      if (this.useBarcodeReader) {
        this.$nextTick(() => this.$refs.trancode.focus());
      }
    }
  },
  computed: {
    ...mapState({
      currentItem: state => state.bigPackage.selected,
      loading: state => state.bigPackage.loading,
      warehouses: state => state.commons.stores,
      customers: state => state.customer.master.data,
      chinaStores: (state) => state.commons.chinaStores,
      configuration: state => state.configuration.selected
    }),
    product_types() {
      let tmp = this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
      return _.filter(tmp, {'shared': 1})
    },
    selectedChinaStoreID() {
      return this.$store.state.authentication.user.chinastoreid;
    },
    CorrectData() {
      return this.currentItem.PackageCode != '';
    },
    isChinessLanguage() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ && this.$store.state.authentication.user.ischinesslanguage;
    },
    headers() {
      return [
        {
          text: this.isChinessLanguage ? "单号" : "Mã bill",
          value: "TransactionCode",
          filterable: true,
          quickSearch: true,
          dataType: configs.DATA_TYPE["String"]
        },
        // {
        //   text: this.isChinessLanguage ? "内部单号" : "Mã nội bộ",
        //   value: "TransactionCodeVN",
        //   filterable: true,
        //   quickSearch: true,
        //   dataType: configs.DATA_TYPE["String"]
        // },
        {
          text: this.isChinessLanguage ? "Kg" : "Kg",
          value: "ChinaWeight",
          align: "end",
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: this.isChinessLanguage ? "货袋尺寸" : "Kích thước",
          value: "ChinaLength",
          align: "end",
          dataType: configs.DATA_TYPE["Number"]
        },
        {
          text: this.isChinessLanguage ? "操作" : "Thao tác",
          value: "",
          align: "center",
          sortable: false,
          filterable: false
        }
      ]
    },
  },
  methods: {
    closePopup() {
      this.$emit("closePopup");
    },
    filter_data: function() {
      if (this.currentItem.ID > 0) {
        var param = {
          pagination: this.pagination
        };
        this.$store.dispatch("bigPackage/getDetail", {'id': this.currentItem.ID, 'param': param});
      }
    },
    deleteTransaction(index) {
      this.currentItem.Packages.splice(index, 1);
      this.currentItem.Volume = this.currentItem.Packages.length;
      this.currentItem.Amount =  _.sumBy(this.currentItem.Packages, 'ChinaFeeShip');
      this.currentItem.Weight =  _.sumBy(this.currentItem.Packages, 'ChinaWeight');
      this.saveItem(false);
      this.$nextTick(() => this.$refs.trancode.focus());
    },
    saveItem(isClose = true) {
      const selectedStore = _.find(this.warehouses, {ID: this.currentItem.ReceivePlaceID})
      if(selectedStore) {
        this.currentItem.StoreShortName = selectedStore.ShortName
      }
      this.$store.dispatch("bigPackage/setDetail", {
        data: {
          ...this.currentItem,
          'Packages': _.map(this.currentItem.Packages, "ID")
        },
        id: this.currentItem.ID
      });
      if(isClose) {
        this.$emit("closePopup");
      }
    },
    manualChanged() {
      if (this.currentTransaction.TransactionCode !== "" && !this.useBarcodeReader) {
        this.appendTransactionCode();
      }
    },
    codeChanged() {
      if (this.currentTransaction.TransactionCode !== "" && this.useBarcodeReader) {
        this.appendTransactionCode();
      }
    },
    appendTransactionCode: _.debounce(
      function() {
         var param = {
            filter: [{
                column: this.useTMDT ? "TransactionCodeVN" : "TransactionCode",
                value: this.currentTransaction.TransactionCode,
                condition: "s_equal"
            },
            {
                column: "StoreCNImportDate",
                condition: "not_null"
            },
            {
                column: "Status",
                value: 2,
                condition: "equal"
            }]
        };
        const checkTran = this.useTMDT ? _.find(this.currentItem.Packages, {TransactionCodeVN: this.currentTransaction.TransactionCode}) : _.find(this.currentItem.Packages, {TransactionCode: this.currentTransaction.TransactionCode})
        if(checkTran) {
          this.currentTransaction = {
            TransactionCode: ""
          };
          this.$nextTick(() => this.$refs.trancode.focus());
          playSound('/vtb-error.mp3');
          sendErrorNotice('Kiện hàng đã có trong bao.')
          return;
        }
        request_list('order_transactions', ret => {
          if(ret.data.length > 0) {
            if(this.currentItem.UID > 0) {
              this.message = ret.data[0].UID != this.currentItem.UID ? `Thông tin khách hàng của kiện ${ret.data[0].TransactionCodeVN || ret.data[0].TransactionCode} không khớp với thông tin đã chọn.` : '';
            }
            
            if(ret.data[0].ChinaWeight == 0) {
              playSound('/vtb-error.mp3');
              sendErrorNotice('Kiện hàng chưa có thông tin cân nặng.')  
              return;
            }

            this.currentItem.Packages.unshift(ret.data[0]);
            this.currentItem.NumberOfPack = this.currentItem.Packages.length;
            this.currentItem.Amount =  _.sumBy(this.currentItem.Packages, 'ChinaFeeShip');
            this.currentItem.Weight =  _.sumBy(this.currentItem.Packages, 'ChinaWeight');
            this.currentTransaction = {
              TransactionCode: ""
            };
            if(this.currentItem.PackageCode == '') {
              this.currentItem.PackageCode = `VTB${moment().format("DDMMYY")}`
              this.currentItem.AutoCode = 1;
            }
            playSound('/vtb-success.mp3');
            this.saveItem(false);
            this.$nextTick(() => this.$refs.trancode.focus());
          }
          else {
            playSound('/vtb-error.mp3');
            sendErrorNotice('Kiện hàng không tồn tại hoặc đã đóng trong bao khác.')
          }              
        }, Vue.http, param, this.$store.state.authentication.token);
      },
      100,
      {
        leading: false,
        trailing: true
      }
    ),
    getCustomerList() {
      this.$store.dispatch("customer/getCustomerList", {
          is_master: 1,
          fields: ["ID", "Username", "CustomerCode"]
      });
    },
    getCustomerName(uid) {
      const cus = _.find(this.customers, {ID: uid});
      return cus ? cus.Username : '';
    },
    onSelectedCustomer() {
      let cusName = this.getCustomerName(this.currentItem.UID);
      cusName = cusName.toUpperCase();
      if(cusName != '') {
        this.currentItem.PackageCode = `${cusName}${moment().format("DDMMYY")}`
        this.currentItem.AutoCode = 1;
      }      
    },
    printBigpackage() {
      if(!this.currentItem.ID) {
        const selectedStore = _.find(this.warehouses, {ID: this.currentItem.ReceivePlaceID})
        this.currentItem.ReceivePlaceName = selectedStore ? selectedStore.Name : '';
        this.currentItem.CreatedBy = this.$store.state.authentication.user.username;
      }
      
      const frame_printing = print_bigpackage(this.currentItem, true);
      delete this.currentItem.ReceivePlaceName;
      delete this.currentItem.CreatedBy;
      var doc = document.getElementById('print_frame').contentWindow.document;
      doc.open();
      doc.write(frame_printing);
      doc.close();
      setTimeout(function(){ window.frames["print_frame"].window.print(); }, 1000);
    },
  },
  mounted() {
    this.getCustomerList();
    this.$store.dispatch("configuration/getDetail", 1);
    this.$store.dispatch("commons/getAllStores");
    this.$store.dispatch("commons/getAllChinaStores");
  }
};
</script>
<style scoped>
.text-error {
  color: #ef4748;
}
</style>