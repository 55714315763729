<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12  v-if="storeVNRole">
        <v-card>
          <hpo-filter
            :headers="headers"
            :viewName="viewName"
            v-on:applyFilter="applyFilter"
            v-on:quickFilter="quickFilter"
          ></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:item="{ item }">
              <tr :id="'r-'+item.ID">
                <td>{{ item.PackageCode }}</td>
                <td class="text-right">{{ item.NumberOfPack | currency }}</td>
                <td class="text-right">{{ item.Weight | currency('', 2) }}</td>
                <td class="text-right" :style="{ 'color': (item.Weight-item.RealWeight)>=1 ? '#ef4748' : '' }">{{ item.RealWeight | currency('', 2) }}</td>
                <td>{{ item.ExDate | display_date }}</td>
                <td>{{ item.ImDate | display_date }}</td>
                <td class="text-center">
                  <v-tooltip top v-if="!exportMenu && (packageRole & 4) && item.Status == bigPackageEnum.DANG_DONG_BAO">
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" medium class="mr-2" @click="exportPackage(item.ID, item.PackageCode)">fa-shipping-fast</v-icon>
                      </template>
                      <span>Xuất kho</span>
                  </v-tooltip>
                  <v-tooltip top v-if="!exportMenu && adminRole && item.Status == bigPackageEnum.XUAT_KHO_TQ">
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" medium class="mr-2" @click="undoExportPackage(item.ID, item.PackageCode)">fa-undo</v-icon>
                      </template>
                      <span>Huỷ xuất kho</span>
                  </v-tooltip>
                  <v-icon medium class="mr-2" @click="gotoDetail(item.ID)"> {{ (packageRole & 8) && item.Status == bigPackageEnum.DANG_DONG_BAO ? 'fa-edit' : 'fa-external-link-alt'}}</v-icon>
                  <v-tooltip top v-if="!exportMenu && (packageRole & 4) && item.Status == bigPackageEnum.DANG_DONG_BAO">
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" medium @click="deleteItem(item.ID)">fa-trash-alt</v-icon>
                      </template>
                      <span>Xoá bao hàng</span>
                  </v-tooltip>
                  <v-icon small color="#dc3545" @click="printBigpackage(item)">fa-print</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:body.append v-if="summary">
                <tr class="table-summary">
                    <td class="font-weight-bold">TỔNG</td>
                    <td class="font-weight-bold text-end">{{ summary.NumberOfPack | currency}}</td>
                    <td class="font-weight-bold text-end">{{ summary.Weight | currency('', 2)}}</td>
                    <td class="font-weight-bold text-end">{{ summary.RealWeight | currency('', 2)}}</td>
                    <td class="font-weight-bold text-end"></td>
                    <td class="font-weight-bold text-end"></td>
                    <td class="font-weight-bold text-end"></td>
                </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-flex lg12  v-else-if="storeTQRole">
        <v-card>
          <hpo-filter
            :headers="headers"
            :viewName="viewName"
            v-on:applyFilter="applyFilter"
            v-on:quickFilter="quickFilter"
          ></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:item="{ item }">
              <tr :id="'r-'+item.ID">
                <td>{{ item.PackageCode }}</td>
                <td class="text-right">{{ item.NumberOfPack | currency }}</td>
                <td class="text-right">{{ item.Weight | currency('', 2) }}</td>
                <td class="text-right" :style="{ 'color': (item.Weight-item.RealWeight)>=1 ? '#ef4748' : '' }">{{ item.RealWeight | currency('', 2) }}</td>
                <td>{{ item.CreatedDate | display_date }}</td>
                <td>{{ item.ExDate | display_date }}</td>
                <td class="text-center">
                  <v-tooltip top v-if="!exportMenu && (packageRole & 4) && item.Status == bigPackageEnum.DANG_DONG_BAO">
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" medium class="mr-2" @click="exportPackage(item.ID, item.PackageCode)">fa-shipping-fast</v-icon>
                      </template>
                      <span>Xuất kho</span>
                  </v-tooltip>
                  <v-tooltip top v-if="!exportMenu && adminRole && item.Status == bigPackageEnum.XUAT_KHO_TQ">
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" medium class="mr-2" @click="undoExportPackage(item.ID, item.PackageCode)">fa-undo</v-icon>
                      </template>
                      <span>Huỷ xuất kho</span>
                  </v-tooltip>
                  <v-icon medium class="mr-2" @click="gotoDetail(item.ID)"> {{ (packageRole & 8) && item.Status == bigPackageEnum.DANG_DONG_BAO ? 'fa-edit' : 'fa-external-link-alt'}}</v-icon>
                  <v-tooltip top v-if="!exportMenu && (packageRole & 4) && item.Status == bigPackageEnum.DANG_DONG_BAO">
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" medium @click="deleteItem(item.ID)">fa-trash-alt</v-icon>
                      </template>
                      <span>Xoá bao hàng</span>
                  </v-tooltip>
                  <v-icon small color="#dc3545" @click="printBigpackage(item)">fa-print</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:body.append v-if="summary">
                <tr class="table-summary">
                    <td class="font-weight-bold">TỔNG</td>
                    <td class="font-weight-bold text-end">{{ summary.NumberOfPack | currency}}</td>
                    <td class="font-weight-bold text-end">{{ summary.Weight | currency('', 2)}}</td>
                    <td class="font-weight-bold text-end">{{ summary.RealWeight | currency('', 2)}}</td>
                    <td class="font-weight-bold text-end"></td>
                    <td class="font-weight-bold text-end"></td>
                    <td class="font-weight-bold text-end"></td>
                </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-flex lg12  v-else>
        <v-card>
          <hpo-filter
            :headers="headers"
            :viewName="viewName"
            v-on:applyFilter="applyFilter"
            v-on:quickFilter="quickFilter"
          ></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:item="{ item }">
              <tr :id="'r-'+item.ID">
                <td>{{ item.PackageCode }}</td>
                <td class="text-right">{{ item.NumberOfPack | currency }}</td>
                <td class="text-right">{{ item.Weight | currency('', 2) }}</td>
                <td class="text-right" :style="{ 'color': (item.Weight-item.RealWeight)>=1 ? '#ef4748' : '' }">{{ item.RealWeight | currency('', 2) }}</td>
                <td>{{ item.CreatedDate | display_date }}</td>
                <td>{{ item.ExDate | display_date }}</td>
                <td>{{ item.ImDate | display_date }}</td>
                <td class="text-center">
                  <v-tooltip top v-if="!exportMenu && (packageRole & 4) && item.Status == bigPackageEnum.DANG_DONG_BAO">
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" medium class="mr-2" @click="exportPackage(item.ID, item.PackageCode)">fa-shipping-fast</v-icon>
                      </template>
                      <span>Xuất kho</span>
                  </v-tooltip>
                  <v-tooltip top v-if="!exportMenu && adminRole && item.Status == bigPackageEnum.XUAT_KHO_TQ">
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" medium class="mr-2" @click="undoExportPackage(item.ID, item.PackageCode)">fa-undo</v-icon>
                      </template>
                      <span>Huỷ xuất kho</span>
                  </v-tooltip>
                  <v-icon medium class="mr-2" @click="gotoDetail(item.ID)"> {{ (packageRole & 8) && item.Status == bigPackageEnum.DANG_DONG_BAO ? 'fa-edit' : 'fa-external-link-alt'}}</v-icon>
                  <v-tooltip top v-if="!exportMenu && (packageRole & 4) && item.Status == bigPackageEnum.DANG_DONG_BAO">
                      <template v-slot:activator="{ on }">
                          <v-icon v-on="on" medium @click="deleteItem(item.ID)">fa-trash-alt</v-icon>
                      </template>
                      <span>Xoá bao hàng</span>
                  </v-tooltip>
                  <v-icon small color="#dc3545" @click="printBigpackage(item)">fa-print</v-icon>
                </td>
              </tr>
            </template>
            <template v-slot:body.append v-if="summary">
                <tr class="table-summary">
                    <td class="font-weight-bold">TỔNG</td>
                    <td class="font-weight-bold text-end">{{ summary.NumberOfPack | currency}}</td>
                    <td class="font-weight-bold text-end">{{ summary.Weight | currency('', 2)}}</td>
                    <td class="font-weight-bold text-end">{{ summary.RealWeight | currency('', 2)}}</td>
                    <td class="font-weight-bold text-end"></td>
                    <td class="font-weight-bold text-end"></td>
                    <td class="font-weight-bold text-end"></td>
                    <td class="font-weight-bold text-end"></td>
                </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
       <big-package-popup
        :readOnly="!((packageRole & 8) && currentItem.Status == bigPackageEnum.DANG_DONG_BAO)"
        :dialogPackageDetail="dialogPackageDetail"
        v-on:closePopup="closePopup"
        @deleteItem="deleteItem"
      ></big-package-popup>
    </v-layout>
    <v-dialog v-model="dialogImport" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="oee-theme">
            <v-btn icon dark @click="dialogImport = false">
              <v-icon>fa-times-circle</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{importStep == 0 ? 'Import thông tin kiện' : 'Xác minh dữ liệu'}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex lg6 md6 sm12 pt-3 v-if="importStep == 0">
                  <v-autocomplete label="Bao hàng" v-model="importPackageID" :items="avaiable_packages" item-text="PackageCode" item-value="ID"></v-autocomplete>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12 pt-3 v-if="importStep == 0">
                  <a href="/package_import_template.xls">File mẫu</a>
                  <v-textarea outlined rows='30' label="Copy dữ liệu từ excel" v-model="dataImport"></v-textarea>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12 pt-3 v-else>
                  <v-data-table fixed-header height="750px"   :headers="importHeaders" :items="importItems" :items-per-page="15" class="elevation-1"></v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1 white--text" @click="importPreview()" v-if="importStep == 0">Tiếp theo</v-btn>
            <v-btn color="blue darken-1 white--text" @click="importStep = 0" v-if="importStep == 1">Quay lại</v-btn>
            <v-btn color="red darken-1 white--text" @click="importTransaction" v-if="importStep == 1">Nhập dữ liệu</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay :value="appLoading" z-index="999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import big_package from "@/components/popups/big_package";
import Filtering from "@/components/Filtering";
import { sendErrorNotice } from "@/commons/utils";
import { print_bigpackage } from "@/commons/hpod";
import _ from "lodash";

export default {
  components: {
    "hpo-filter": Filtering,
    "big-package-popup": big_package,
  },
  props: ["exportMenu"],
  data() {    
    return {      
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "bigpackage_list",
      filterConditions: [],
      quickFilterConditions: [],
      requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
      bigPackageEnum: configs.BIG_PACKAGE_STATUS_ENUM,
      dialogPackageDetail: false,
      dialogImport: false,
      dataImport: '',      
      importPackageID: 0,
      importStep: 0,
      appLoading: false,
      importHeaders: [
        {
          text: this.isChinessLanguage ? "运单号" : "Mã kiện",
          value: "TransactionCode"
        },
        {
          text: this.isChinessLanguage ? "产品类型" : "Loại hàng",
          value: "ProductType"
        },
        {
          text: this.isChinessLanguage ? "客户" : "Khách hàng",
          value: "Username"
        },
        {
          text: this.isChinessLanguage ? "入库日期" : "Ngày nhập kho TQ",
          value: "StoreCNImportDate"
        },
        {
          text: this.isChinessLanguage ? "重量" : "Cân nặng",
          value: "ChinaWeight"
        },
        {
          text: this.isChinessLanguage ? "长度 (cm)" : "Chiều dài (cm)",
          value: "ChinaLength"
        },
        {
          text: this.isChinessLanguage ? "宽度 (cm)" : "Chiều rộng (cm)",
          value: "ChinaWidth"
        },
        {
          text: this.isChinessLanguage ? "高度 (cm)" : "Chiều cao (cm)",
          value: "ChinaHeight"
        },
        {
          text: this.isChinessLanguage ? "数量" : "Số lượng",
          value: "RealNumberOfPack"
        },
        {
          text: this.isChinessLanguage ? "加固费" : "Gia cố",
          value: "FeeFragileGoods"
        },
        {
          text: this.isChinessLanguage ? "其他费用" : "Phí khác",
          value: "FeeOther"
        }
      ],
      importItems: []
    };
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions)
          ? filterConditions[this.viewName]
          : [];
        this.filter_data();
      },
      deep: true
    },
    packageRole() {
      this.$root.$emit('REQUEST_ROOT_OPTION', {invisibleCreateButton: !(this.packageRole & 2)});
    }
  },
  computed: {
    ...mapState({
      items: state => state.bigPackage.all.data,
      total_rows: state => state.bigPackage.all.total,
      summary: state => state.bigPackage.all.summary,
      currentItem: state => state.bigPackage.selected,
      loading: state => state.bigPackage.loading,
      avaiable_packages: state => state.bigPackage.inTQ,
      configuration: state => state.configuration.selected,
      customers: state => state.customer.master.data,
    }),
    packageRole() {
      return this.$store.state.authentication.role.BigPackage;
    },
    adminRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
    },
    storeVNRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreVN) == configs.ROLE_TYPE.StoreVN;
    },
    storeTQRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ;
    },
    product_types() {
      return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
    },
    isChinessLanguage() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ && this.$store.state.authentication.user.ischinesslanguage;
    },
    param() {
      let filter_param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      if(this.exportMenu) {
        filter_param.filter.push({
          column: "Status",
          value: [configs.BIG_PACKAGE_STATUS_ENUM.XUAT_KHO_TQ],
          condition: "in",
        })
      }  
      return filter_param;
    },
    headers() {
      let dataType = configs.DATA_TYPE;
      let header_admin = [
        {
          text: this.isChinessLanguage ? "包裹编号" : "Bao hàng",
          value: "PackageCode",
          filterable: true,
          quickSearch: true,
          dataType: dataType["String"]
        },
        {
          text: this.isChinessLanguage ? "数量" : "Số gói",
          value: "NumberOfPack",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: this.isChinessLanguage ? "重量" : "Cân nặng",
          value: "Weight",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: this.isChinessLanguage ? "实际重量" : "Cân thực tế",
          value: "RealWeight",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: this.isChinessLanguage ? "创建日期" : "Ngày tạo",
          value: "CreatedDate",
          filterable: true,
          dataType: dataType["Date"]
        },
        {
          text: this.isChinessLanguage ? "出货日期" : "Ngày xuất kho",
          value: "ExDate",
          filterable: true,
          dataType: dataType["Date"]
        },
        {
          text: this.isChinessLanguage ? "入仓日期" : "Ngày nhập kho",
          value: "ImDate",
          filterable: true,
          dataType: dataType["Date"]
        },
        {
          text: this.isChinessLanguage ? "操作" : "Thao tác",
          value: "",
          align: "center",
          sortable: false,
          filterable: false
        }
      ];

      if (this.storeVNRole) {
        header_admin = [
            {
              text: this.isChinessLanguage ? "包裹编号" : "Bao hàng",
              value: "PackageCode",
              filterable: true,
              quickSearch: true,
              dataType: dataType["String"]
            },
            {
              text: this.isChinessLanguage ? "数量" : "Số gói",
              value: "Volume",
              align: "end",
              filterable: true,
              dataType: dataType["Number"]
            },
            {
              text: this.isChinessLanguage ? "重量" : "Cân nặng",
              value: "Weight",
              align: "end",
              filterable: true,
              dataType: dataType["Number"]
            },
            {
              text: this.isChinessLanguage ? "实际重量" : "Cân thực tế",
              value: "RealWeight",
              align: "end",
              filterable: true,
              dataType: dataType["Number"]
            },
            {
              text: this.isChinessLanguage ? "出货日期" : "Ngày xuất kho",
              value: "ExDate",
              filterable: true,
              dataType: dataType["Date"]
            },
            {
              text: this.isChinessLanguage ? "入仓日期" : "Ngày nhập kho",
              value: "ImDate",
              filterable: true,
              dataType: dataType["Date"]
            },
            {
              text: this.isChinessLanguage ? "操作" : "Thao tác",
              value: "",
              align: "center",
              sortable: false,
              filterable: false
            }
          ];
      }

      if (this.storeTQRole) {
        header_admin = [
            {
              text: this.isChinessLanguage ? "包裹编号" : "Bao hàng",
              value: "PackageCode",
              filterable: true,
              quickSearch: true,
              dataType: dataType["String"]
            },
            {
              text: this.isChinessLanguage ? "数量" : "Số gói",
              value: "NumberOfPack",
              align: "end",
              filterable: true,
              dataType: dataType["Number"]
            },
            {
              text: this.isChinessLanguage ? "重量" : "Cân nặng",
              value: "Weight",
              align: "end",
              filterable: true,
              dataType: dataType["Number"]
            },
            {
              text: this.isChinessLanguage ? "实际重量" : "Cân thực tế",
              value: "RealWeight",
              align: "end",
              filterable: true,
              dataType: dataType["Number"]
            },
            {
              text: this.isChinessLanguage ? "创建日期" : "Ngày tạo",
              value: "CreatedDate",
              filterable: true,
              dataType: dataType["Date"]
            },
            {
              text: this.isChinessLanguage ? "出货日期" : "Ngày xuất kho",
              value: "ExDate",
              filterable: true,
              dataType: dataType["Date"]
            },
            {
              text: this.isChinessLanguage ? "操作" : "Thao tác",
              value: "",
              align: "center",
              sortable: false,
              filterable: false
            }
          ];
      }
      return header_admin;
    }
  },
  methods: {
    filter_data: function() {
      this.appLoading = false;
      this.$store.dispatch("bigPackage/getListInTQ");
      this.$store.dispatch("bigPackage/getList", this.param);
    },
    exportPackage(id, package_code) {
      window.getApp.$emit("REQUEST_CONFIRM_ACTION", 'ExportStoreCN', id, `Bạn có chắc chắn xuất kho bao hàng ${package_code} không?`);
    },
    undoExportPackage(id, package_code) {
      window.getApp.$emit("REQUEST_CONFIRM_ACTION", 'UnExportStoreCN', id, `Bạn có chắc chắn huỷ xuất kho bao hàng ${package_code} không?`);
    },
    gotoDetail: function(id) {
      let configPaging = _.cloneDeep(configs.PAGINATION);
      configPaging.itemsPerPage = 5;
      var param = {
         pagination: {
            ...configPaging,
            itemsPerPage: 1000000
          }
      };
      
      this.$store.dispatch("bigPackage/getDetail", {'id': id, 'param': param});
      setTimeout(() => {this.dialogPackageDetail = true}, 200);
    },
    deleteItem: function(id) {
      if(this.packageRole & 4) {
        window.getApp.$emit("REQUEST_DELETE_DATA", id);
      }
      else {
        sendErrorNotice('Bạn không có quyền xóa bao hàng.')
      }
    },    
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    createData(username = "") {
      // Bỏ qua nếu event bắn từ menu xuất kho tq
      if(this.exportMenu) {
        return;
      }
      if(this.packageRole & 2) {
        this.$store.commit("bigPackage/setDetail", {
          data: {
            ID: 0,
            PackageCode: '',
            ProductType: 5,
            ReceivePlaceID: 1,
            Weight: 0,
            Volume: 0,
            Amount: 0,
            Status: 0,
            Packages: []
          }
        });
        this.dialogPackageDetail = true;
      }
      else {
        sendErrorNotice('Bạn không có quyền thêm mới bao hàng.')
      }
    },
    saveItem(item) {
      this.$store.dispatch("bigPackage/setDetail", {
        data: item,
        id: item.ID,
        param: this.param
      });
    },
    saveData() {
      this.saveItem(this.currentItem);
      this.dialogPackageDetail = false;
    },
    closePopup() {
      this.dialogPackageDetail = false;
      setTimeout(this.filter_data, 200);
    },
    getCustomerList() {
      this.$store.dispatch("customer/getCustomerList", {
          is_master: 1,
          fields: ["ID", "Username", "CustomerCode"]
      });
    },
    importData() {
      this.dataImport = '';
      this.importItems = [];
      this.dialogImport = true;
    },
    importPreview(){
      this.importItems = [];
      let rows = this.dataImport.split("\n");
      if(rows.length <= 1){
        sendErrorNotice('Không tìm thấy dữ liệu import.')
        return;
      }
      let header = rows[0].split("\t");
      let props = [];
      for (let i = 0; i < header.length; i++) {
        let element = _.find(this.importHeaders, function (item) {
          return item.text.toLowerCase() === header[i].toLowerCase();
        });
        props.push(element ? element.value : 'unknown');
      }

      if(!props.includes('TransactionCode')) {
        sendErrorNotice('Không tìm thấy dữ liệu import.')
        return;
      }
      this.importStep = 1;
      for (let i = 1; i < rows.length; i++) {
        let item = rows[i].split("\t");
        if(item.length == 0 || item[0] == '') {
          continue;
        }
        let importItem = {};
        for (let j = 0; j < props.length; j++) {
          let prop = props[j];
          if(prop !== 'unknown') {
            let val = item[j].replace(/\s+/g, ' ').trim();
            if(val != '') {
              importItem[prop] = val;
            }
          }
        }
        this.importItems.push(importItem);
      }
    },
    importTransaction() {
      // let selected_bigpackage = _.find(this.avaiable_packages, {'ID': this.importPackageID});
      // let selected_uid = selected_bigpackage ? selected_bigpackage.UID : null;
      this.importItems.forEach(element => {
        // if(selected_uid) {
        //   element.UID = selected_uid;
        // }
        // else 
        if(element.Username) {
            let conf = _.find(this.customers, function (item) {
                let ret = item.CustomerCode && item.CustomerCode.normalize('NFD').toLowerCase() === element.Username.normalize('NFD').toLowerCase();
                return ret;
            });
            if(conf) {
                element.UID = conf.ID;
                // selected_uid = element.UID;
            }
            else {
                delete element.UID;
            }
        }

        if(element.ProductType) {
            let conf = _.find(this.product_types, function (item) {
                let ret = item.name.normalize('NFD').toLowerCase() === element.ProductType.normalize('NFD').toLowerCase();
                return ret;
            });
            if(conf) {
                element.ProductType = conf.value;
            }
            else {
                delete element.ProductType;
            }
        }
        if(this.importPackageID > 0) {
          element.BigPackageID = this.importPackageID;
        }
      });
      this.appLoading = true;
      this.$store.dispatch("orderTransaction/import", {'Transactions': this.importItems});
      // if(!selected_bigpackage.UID && selected_uid) {
      //   this.$store.dispatch("bigPackage/setDetail", {
      //     data: {'ID': selected_bigpackage.ID, 'UID': selected_uid},
      //     id: selected_bigpackage.ID,
      //     param: this.param
      //   });
      // }
      this.dialogImport = false;
      this.importStep = 0;
      this.importPackageID = 0;
    },
    printBigpackage(item) {
      const frame_printing = print_bigpackage(item, true);
      var doc = document.getElementById('print_frame').contentWindow.document;
      doc.open();
      doc.write(frame_printing);
      doc.close();
      setTimeout(function(){ window.frames["print_frame"].window.print(); }, 1000);
    },
    exportData() {
      this.$store.dispatch("bigPackage/export", this.param);
    }
  },
  mounted() {
    this.getCustomerList();
    this.$store.dispatch("configuration/getDetail", 1);
    this.$root.$emit('REQUEST_ROOT_OPTION', {invisibleCreateButton: !(this.packageRole & 2)});
    this.$root.$on("REQUEST_OTHER_ACTION", this.exportData);
    this.$root.$on("REQUEST_SAVE_DATA", this.importData);
    this.$root.$on("REQUEST_CREATE_DATA", this.createData);
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
      this.$store.dispatch("bigPackage/removeDetail", id);
      setTimeout(this.filter_data, 200);
    });
    this.$root.$on("CONFIRMED_ACTION", ({action, id}) => {
      if(action == 'ExportStoreCN') {
        this.saveItem({
          ID: id,
          Status: configs.BIG_PACKAGE_STATUS_ENUM.XUAT_KHO_TQ
        });
      }
      else if(action == 'UnExportStoreCN') {
        this.saveItem({
          ID: id,
          Status: configs.BIG_PACKAGE_STATUS_ENUM.DANG_DONG_BAO
        });
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_CREATE_DATA", this.createData);
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    this.$root.$off("REQUEST_SAVE_DATA", this.importData);
    this.$root.$off("REQUEST_OTHER_ACTION", this.exportData);
    this.$root.$off("CONFIRMED_REMOVE_DATA");
    this.$root.$off("CONFIRMED_ACTION");
  }
};
</script>